.comunicadosData {
    width: 8%;
    height: 100px;
    color: #444;
    text-align: center;
    padding: 20px 0;
    font-weight: 900;
    background: #d6d6d6;
    float: left;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
}

.comunicadosData:hover {
    color: #000;
}

.comunicadosData .comunicadosMes {
    font-size: 14px;
    text-align: center;
    line-height: 9px;
}

.comunicadosData .comunicadosDia {
    font-size: 25px;
    line-height: 19px;
    padding: 10px 0;
}

.comunicadosData .comunicadosAno {
    font-size: 14px;
    text-align: center;
    line-height: 9px;
}

.body {
    float: right;
    width: 92%;
    height: 100px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d6d6d6;
}

.titulo {
    margin-left: 20px;
    color: #444 !important;
    font-weight: 900;
    line-height: 24px;
    // font: bold 20px/24px Arial
}

.titulo:hover {
    color: #000;
}

h4 {
    margin-top: -4px !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.post {
    text-align: right;
    font-size: 12px;
    color: #4e4e4e;
}

.TextoCorpo {
    text-align: left;
    margin-left: 20px;
    color: #444;
    font: 14px/24px Arial;
}

.TextoCorpo:hover {
    color: #000;
}

.DisabledCursor {
    cursor: default !important;
    color: #fae2e2 !important;
}