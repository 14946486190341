.Title {
    color: #000000;
    margin-top: 15px;
    font-size: 1.1em;
    min-height: auto;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-decoration: none;
}

.LabelHorizontal {
    cursor: default !important;
    display: inline-flex !important;
    font-size: 15px !important;
    line-height: 1.428571429 !important;
    font-weight: 400 !important;
    padding-top: 39px !important;
    margin-right: 0 !important;
    // @media (min-width: 992px): {
    //   float: left //#FormLabel
    // }
}

.Error {
    color: #ff0000 !important;
}

.Button {
    margin: 10px 0 !important;
    color: #fff !important;
}

.SearchButton {
    padding: 2px 16px !important;
}

.Right {
    text-align: right !important;
}

.Center {
    text-align: center !important;
}

.TitleSession {
    font-weight: bold;
    font-size: 18px;
    color: #3b5998;
    text-transform: uppercase;
    padding-bottom: 10px;
}